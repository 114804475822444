import { Card, Container, Grid2 as Grid, Stack, Typography } from '@mui/material';
import { KmxDiamondStroked, KmxLocationStroked, KmxShieldCheckStroked } from '@kmx/mui-icons';

interface IContent {
    icon: JSX.Element;
    text: string;
    disclaimer?: string;
}

const colors = {
    neutral: '#2a343d',
    neutralWeak: '#DEDFE0',
};

const iconStyles = { height: '40px', width: '40px', color: colors.neutral };

const cardStyles = {
    minHeight: {
        sm: '195px',
    },
    borderRadius: {
        xs: 0,
        sm: 2,
    },
    border: {
        xs: 0,
        sm: `1px solid ${colors.neutralWeak}`,
    },
    padding: {
        xs: 0,
    },
    pt: {
        sm: 4,
    },
    pb: {
        xs: 2,
        sm: 5,
    },
    px: {
        xs: 0,
        sm: 3,
        md: 5,
    },
};

const content: IContent[] = [
    {
        icon: <KmxDiamondStroked sx={iconStyles} />,
        text: 'Upfront prices, CarMax Certified quality, and detailed history reports on every car',
    },
    {
        icon: <KmxLocationStroked sx={iconStyles} />,
        text: 'Shop online, in-store, or both. We provide no-pressure help along the way.',
    },
    {
        icon: <KmxShieldCheckStroked sx={iconStyles} />,
        text: '10-day returns and 90-day or 4,000 mile limited warranty (whichever comes first)',
        disclaimer: 'See store for written details.',
    },
];

export const TheCarMaxDifference = () => {
    return (
        <Container sx={{ px: { xs: 3, sm: 5, lg: 3 }, mt: { xs: 7, md: 11 }, mb: { xs: 15, md: 23 } }}>
            <Typography component="h2" variant="h4" color={colors.neutral} sx={{ mb: 6 }}>
                The CarMax difference
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, md: 3 }}>
                {content.map((item, i) => (
                    <Grid key={i} size={{ xs: 12, sm: 4 }}>
                        <Card elevation={0} sx={cardStyles}>
                            {item.icon}
                            <Typography component="div" variant="body1" color={colors.neutral}>
                                {item.text}
                            </Typography>
                            {item.disclaimer && (
                                <Typography
                                    component="div"
                                    variant="caption"
                                    color="text.secondary"
                                    fontStyle="italic"
                                    sx={{ mb: -2.5 }}
                                >
                                    {item.disclaimer}
                                </Typography>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Stack>
        </Container>
    );
};
