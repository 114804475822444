export const allPriorUses = [
    {
        id: 10,
        displayName: 'Fleet',
    },
    {
        id: 20,
        displayName: 'Leased Vehicle',
    },
    {
        id: 30,
        displayName: 'Rental',
    },
    {
        id: 50,
        displayName: 'Taxi',
    },
    {
        id: 60,
        displayName: 'Police',
    },
    {
        id: 70,
        displayName: 'Government Use',
    },
    {
        id: 100,
        displayName: 'Driver Education',
    },
    {
        id: 140,
        displayName: 'Canadian Renewal',
    },
    {
        id: 440,
        displayName: 'Miles Exempt',
    },
    {
        id: 580,
        displayName: 'Court Ordered Title',
    },
    {
        id: 620,
        displayName: 'Livery',
    },
    {
        id: 630,
        displayName: 'Prior Theft History',
    },
    {
        id: 680,
        displayName: 'Kit',
    },
    {
        id: 681,
        displayName: 'Police (Nmvtis)',
    },
    {
        id: 683,
        displayName: 'Possible Aftermarket Mods',
    },
    {
        id: 684,
        displayName: 'Rental (Nmvtis)',
    },
    {
        id: 685,
        displayName: 'Miles Exempt (Nmvtis)',
    },
    {
        id: 686,
        displayName: 'Canadian Odometer Reading',
    },
    {
        id: 687,
        displayName: 'Canadian Import',
    },
    {
        id: 697,
        displayName: 'Prior Theft History',
    },
];
