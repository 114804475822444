// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { formatSeperatedInteger } from '../../../../../components/instant-offers/utils/format';
import { allPriorUses } from './allPriorUses';

/**
 * Conditionally adds a string value to the provided line items collection.
 * @param {string} label - the label of the item to add.
 * @param {string} value - the value to add.
 * @param {array} lineItems - array of line items to augment.
 */
const addStringLineItem = (label, value, lineItems) => {
    if (value && typeof value === 'string') {
        lineItems.push({
            label,
            value,
        });
    }
};

/**
 * Conditionally adds a number value to the provided line items collection.
 * @param {string} label - the label of the item to add.
 * @param {number} value - the value to add.
 * @param {boolean} addCommas - indicates whether the value should be formatted with commas.
 * @param {array} lineItems - array of line items to augment.
 */
const addNumberLineItem = (label, value, addCommas, lineItems) => {
    if (typeof value === 'number') {
        const numberValue = Number(value);

        lineItems.push({
            label,
            value: addCommas ? formatSeperatedInteger(numberValue) : numberValue.toString(),
        });
    }
};

/**
 * Conditionally adds an array of string values to the provided line items collection.
 * @param {string} label - the label of the item to add.
 * @param {array} value - the array of values to add.
 * @param {array} lineItems - array of line items to augment.
 */
const addStringArrayLineItem = (label, value, lineItems) => {
    if (Array.isArray(value) && value.length > 0 && value.every((v) => typeof v === 'string')) {
        lineItems.push({
            label,
            value: value.join(', '),
        });
    }
};

const excludedHighlights = ['warranty'];
const highlightValuesToDescriptions = {
    singleowner: 'Single Owner',
    allnewtires: 'All New Tires',
    newpairtires: 'New Pair of Tires',
    lowmiles: 'Low Miles',
    lowmilesperyear: 'Low Miles Per Year',
    fuelefficient: 'Fuel Efficient',
    advancedfeatures: 'Advanced Features',
    premiumaudio: 'Premium Audio',
};

export const extractBaseSpecificationsLineItems = (car) => {
    const {
        body,
        vehicleSize,
        series,
        types,
        mileage,
        storeCity,
        state,
        packages,
        priorUses,
        mpgCity,
        mpgHighway,
    } = car;

    const lineItems = [];

    addStringLineItem('Body', body, lineItems);
    addStringLineItem('Vehicle Size', vehicleSize, lineItems);
    addStringLineItem('Series', series, lineItems);
    addStringArrayLineItem('Type', types, lineItems);
    addNumberLineItem('Mileage', mileage, true, lineItems);

    if (storeCity || state) {
        let cityState = storeCity || '';
        if (state) {
            cityState = `${cityState}${cityState.length ? ', ' : ''}${state}`;
        }

        lineItems.push({
            label: 'City, State',
            value: cityState,
        });
    }
    addStringArrayLineItem('Package', packages, lineItems);

    if (!priorUses?.length) {
        lineItems.push({
            label: 'Prior Use',
            value: 'None',
        });
    } else {
        const displayNames = priorUses
            .map((pu) => {
                const priorUseWithDisplayName = allPriorUses.find((apu) => apu.id === +pu);
                if (!priorUseWithDisplayName) {
                    return null;
                }

                return priorUseWithDisplayName.displayName;
            })
            .filter((pu) => !!pu);

        addStringArrayLineItem('Prior Use', displayNames, lineItems);
    }

    if (mpgCity || mpgHighway) {
        let mpg = mpgCity ? mpgCity.toString() : '';
        if (mpgHighway) {
            mpg = `${mpg}${mpg ? '/' : ''}${mpgHighway.toString()}`;
        }

        lineItems.push({
            label: 'City/Highway MPG',
            value: mpg,
        });
    }

    return lineItems;
};

export const extractColorsLineItems = (car) => {
    const { exteriorColor, interiorColor } = car;

    const lineItems = [];

    addStringLineItem('Exterior', exteriorColor, lineItems);
    addStringLineItem('Interior', interiorColor, lineItems);

    return lineItems;
};

export const extractEngineLineItems = (car) => {
    const {
        engineSize,
        engineType,
        engineTorque,
        engineTorqueRpm,
        horsepower,
        horsepowerRpm,
        cylinders,
        fuelType,
        driveTrain,
        transmission,
    } = car;

    const lineItems = [];

    // if engine size is not null and the engine size string contains a positive float
    if (engineSize && Number(engineSize.replace(/[^0-9]+/g, '')) > 0) {
        addStringLineItem('Engine Size', engineSize, lineItems);
    }

    addStringLineItem('Engine Type', engineType, lineItems);

    if (engineTorque || engineTorqueRpm) {
        let torqueValue = engineTorque ? engineTorque.toString() : '';
        if (engineTorqueRpm) {
            torqueValue = `${torqueValue}${torqueValue ? '/' : ''}${engineTorqueRpm.toString()} RPM`;
        }

        lineItems.push({
            label: 'Engine Torque',
            value: torqueValue,
        });
    }

    if (horsepower || horsepowerRpm) {
        let horsepowerValue = horsepower ? horsepower.toString() : '';
        if (horsepowerRpm) {
            horsepowerValue = `${horsepowerValue}${horsepowerValue ? '/' : ''}${horsepowerRpm.toString()} RPM`;
        }

        lineItems.push({
            label: 'Horsepower',
            value: horsepowerValue,
        });
    }

    if (cylinders && cylinders !== '0') {
        addNumberLineItem('Cylinders', cylinders, false, lineItems);
    }

    addStringLineItem('Fuel Type', fuelType, lineItems);
    addStringLineItem('Drive Train', driveTrain, lineItems);
    addStringLineItem('Transmission', transmission, lineItems);

    return lineItems;
};

export const extractKeyFeatureLineItems = (car) => {
    const { highlightedFeatures } = car;

    if (!highlightedFeatures || highlightedFeatures.length === 0) {
        return [];
    }

    return highlightedFeatures.map((f) => ({
        label: null,
        value: f.trim(),
    }));
};

export const extractHighlightsLineItems = (car) => {
    const { highlights } = car;

    if (!highlights) {
        return [];
    }

    // Highlights come over as merged strings in camel case.
    const readableHighlights = highlights
        .filter((h) => !excludedHighlights.includes(h?.toLowerCase()))
        .map((h) => highlightValuesToDescriptions[h?.toLowerCase()] || h);

    return readableHighlights.map((h) => ({
        label: null,
        value: h,
    }));
};

export const extractFeaturesLineItems = (car) => {
    const { featureScore, features } = car;

    let lineItems = [];
    if (features) {
        lineItems = features.map((f) => ({
            label: null,
            value: f,
        }));
    }

    if (typeof featureScore === 'number') {
        let featureScoreCategory = 'Basic';
        if (featureScore > 3 && featureScore < 8) {
            featureScoreCategory = 'Well-Equipped';
        } else if (featureScore >= 8) {
            featureScoreCategory = 'Loaded';
        }

        lineItems.unshift({
            label: 'Feature Summary',
            value: `${featureScoreCategory} (${featureScore})`,
        });
    }

    return lineItems;
};

export const extractRatingsAndReviewsLineItems = (car) => {
    const { averageRating, numberOfReviews } = car;

    const lineItems = [];

    if (typeof averageRating === 'number' && averageRating !== 0) {
        addStringLineItem('Average Rating', `${Number(averageRating).toFixed(2)}/5`, lineItems);
    }

    if (numberOfReviews) {
        addNumberLineItem('Number of Reviews', numberOfReviews, true, lineItems);
    }

    return lineItems;
};
