import Icons from '../../../../../../utilities/icons';
import './RelatedSearchesTile.scss';
import { getVehiclesTotalCount } from '../../../../../../api/search-api';
import { MouseEvent, useEffect, useState } from 'react';
import { useLocalCarsStore } from '../../../../../../context/localCarsStore';
import { formatSeperatedInteger } from '../../../../../instant-offers/utils/format';
import { ANALYTICS_CONSTANTS, trackAnchorClick } from '../../../../../../utilities/analytics';

interface CardProps {
    imgSrc: string;
    title: string;
    subtitle: string;
    href: string;
}

const handleClick = (e: MouseEvent, title: string, href: string) => {
    trackAnchorClick({
        clickEvent: e,
        taggingEvent: ANALYTICS_CONSTANTS.EVENTS.CLICK.SEARCH_INITIATED,
        title,
        position: 'shop cars near me',
        href,
        page: 'Homepage',
    });
};

const Card = ({ imgSrc, title, subtitle, href }: CardProps) => (
    <a onClick={(e: MouseEvent) => handleClick(e, title, href)} className="related-searches-tile__card" href={href}>
        <div className="related-searches-tile__card-content">
            <img src={`/home/images/related-searches-tile/related-searches-${imgSrc}`} alt={title} />
            <div>
                <span className="related-searches-tile__card-title">{title}</span>
                <span>{subtitle}</span>
            </div>
        </div>
        {Icons.rightArrowIcon}
    </a>
);

export const RelatedSearchesTile: React.FC = () => {
    const [testDriveCount, setTestDriveCount] = useState(0);
    const [localPriceDropsCount, setLocalPriceDropsCount] = useState(0);
    const [freeShippingCount, setFreeShippingCount] = useState(0);
    const [allCarsCounts, setAllCarsCounts] = useState(0);
    const { storeId } = useLocalCarsStore();

    useEffect(() => {
        (async () => {
            const defaultSearchProps = { storeId, radiusInMiles: 25 };

            const fetchTestDrive = async () => {
                const totalCount = await getVehiclesTotalCount({
                    ...defaultSearchProps,
                    isInStoreOnly: true,
                });
                setTestDriveCount(totalCount);
            };

            const fetchLocalPriceDrops = async () => {
                const totalCount = await getVehiclesTotalCount({
                    ...defaultSearchProps,
                    hasPriceDrop: true,
                });
                setLocalPriceDropsCount(totalCount);
            };

            const fetchFreeShipping = async () => {
                const totalCount = await getVehiclesTotalCount({
                    ...defaultSearchProps,
                    hasFreeShipping: true,
                });
                setFreeShippingCount(totalCount);
            };

            const fetchAll = async () => {
                const totalCount = await getVehiclesTotalCount({});
                setAllCarsCounts(totalCount);
            };

            await Promise.all([fetchAll(), fetchTestDrive(), fetchLocalPriceDrops(), fetchFreeShipping()]);
        })();
    }, [storeId]);

    return (
        <article className="scct--car-tile">
            <div className="carousel__slide">
                <div className="carousel-slide-item">
                    <div className="related-searches-tile">
                        {testDriveCount > 0 && (
                            <Card
                                imgSrc="1.png"
                                title="Test drive today"
                                subtitle={`${testDriveCount} matches`}
                                href="/cars?distance=25&comingsoon=false"
                            />
                        )}
                        {localPriceDropsCount > 0 && (
                            <Card
                                imgSrc="2.png"
                                title="Local price drops"
                                subtitle={`${localPriceDropsCount} matches`}
                                href="/cars?distance=25&flag_PriceDrop=true"
                            />
                        )}
                        {freeShippingCount > 0 && (
                            <Card
                                imgSrc="3.png"
                                title="Free shipping"
                                subtitle={`${freeShippingCount} matches`}
                                href="/cars?shipping=0&comingsoon=false"
                            />
                        )}
                        {allCarsCounts > 0 && (
                            <Card
                                imgSrc="4.png"
                                title="Shop all cars"
                                subtitle={`${formatSeperatedInteger(allCarsCounts)} matches`}
                                href="/cars/all"
                            />
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
};
