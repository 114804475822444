import { Dispatch, SetStateAction, useMemo } from 'react';
import Dialog from '../../../../components/Dialog';
import { IVehicleTile } from '../../../../types/vehicleTile';
import {
    extractBaseSpecificationsLineItems,
    extractColorsLineItems,
    extractEngineLineItems,
    extractFeaturesLineItems,
    extractHighlightsLineItems,
    extractKeyFeatureLineItems,
    extractRatingsAndReviewsLineItems,
} from './utils/additionalVehicleInfoHelper';
import './VehicleDetailsDialog.scss';

interface VehicleDetailsDialogProps {
    isVisible: boolean;
    vehicle: IVehicleTile;
    setIsDialogVisible: Dispatch<SetStateAction<boolean>>;
}

const Section = ({ title, items }: { title: string; items: { label: string; value: string }[] }) => {
    const withLabels = useMemo(() => items.filter((li) => !!li.label), [items]);
    const withoutLabels = useMemo(() => items.filter((li) => !li.label), [items]);

    if (items.length > 0) <></>;

    if (withLabels.length === 0 && withoutLabels.length === 0) {
        return null;
    }

    // Display Features section
    if (title === 'All features') {
        return (
            <section>
                <h2>{title}</h2>
                <div className="label-value-list">
                    <div>{items[0].label}</div>
                    <div>{items[0].value}</div>
                </div>
                <div className="values-only-list">
                    {items.slice(1).map((item, i) => (
                        <div key={i}>{item.value}</div>
                    ))}
                </div>
            </section>
        );
    }

    // Display values only
    if (withoutLabels.length === items.length) {
        return (
            <section>
                <h2>{title}</h2>
                <div className="values-only-list">
                    {items.map((item, i) => (
                        <div key={i}>{item.value}</div>
                    ))}
                </div>
            </section>
        );
    }

    // Display label and value pairs
    return (
        <section>
            <h2>{title}</h2>
            {items.map((item, i) => (
                <div key={i} className="label-value-list">
                    <div>{item.label}</div>
                    <div>{item.value}</div>
                </div>
            ))}
        </section>
    );
};

export const VehicleDetailsDialog = ({ isVisible, vehicle, setIsDialogVisible }: VehicleDetailsDialogProps) => {
    return (
        <Dialog isVisible={isVisible} onClose={() => setIsDialogVisible(false)}>
            <div className="vehicle-details-modal">
                <h3>About this car</h3>
                <div className="vehicle-info">
                    <div>
                        <hzn-copy-button value={vehicle.stockNumber?.toString()}></hzn-copy-button>
                        Stock {vehicle.stockNumber}
                    </div>
                    <div>
                        <hzn-copy-button value={vehicle.vin}></hzn-copy-button>
                        VIN {vehicle.vin}
                    </div>
                </div>
                <div className="vehicle-info-list">
                    <Section title="Base specifications" items={extractBaseSpecificationsLineItems(vehicle)} />
                    <Section title="Colors" items={extractColorsLineItems(vehicle)} />
                    <Section title="Engine" items={extractEngineLineItems(vehicle)} />
                    <Section title="Key features" items={extractKeyFeatureLineItems(vehicle)} />
                    <Section title="Highlights" items={extractHighlightsLineItems(vehicle)} />
                    <Section title="All features" items={extractFeaturesLineItems(vehicle)} />
                    <Section title="Ratings &amp; reviews" items={extractRatingsAndReviewsLineItems(vehicle)} />
                </div>
            </div>
        </Dialog>
    );
};
