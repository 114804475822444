import { IVehicleRecommendationsApiResponse, Vehicle } from './types';

// TODO: Set this max. value on the server
const maxRecommendations = 10;

interface getVehicleRecommendationsParams {
    stockNumberList: string[];
    lat: number;
    long: number;
    visitorId: string;
    userStoreId: string;
}

export async function getVehicleRecommendations({
    stockNumberList,
    lat,
    long,
    visitorId,
    userStoreId,
}: getVehicleRecommendationsParams): Promise<Vehicle[]> {
    try {
        if (stockNumberList && stockNumberList.length > 0) {
            const sortedStockNumberList = stockNumberList.sort();
            const searchParams = new URLSearchParams();
            searchParams.set('visitorId', visitorId);
            searchParams.set('userStoreId', userStoreId);
            searchParams.set('lat', lat.toString());
            searchParams.set('lon', long.toString());
            sortedStockNumberList.forEach((s) => {
                searchParams.append('stockNumbers', s);
            });

            const url = `/home/api/recommendations/?${searchParams.toString()}`;
            return await fetch(url)
                .then((response) => response.json())
                .then((data: IVehicleRecommendationsApiResponse) => {
                    if (data && data.length > 0) {
                        return data.slice(0, maxRecommendations);
                    }
                    return [];
                })
                .catch(() => []);
        }
        return Promise.resolve([]);
    } catch {
        return Promise.resolve([]);
    }
}
