import * as React from 'react';
import Carousel from './Carousel';
import { SkeletonVehicleTile } from './SkeletonVehicleTile';

export const RecommendationsPlaceholderCarousel: React.FC = () => {
    return (
        <div className="home-base-hero--tab-placeholder home-base-hero--recommandations-tab-placeholder carousel-container">
            <ul className="tab-placeholder--list kmx-list">
                <Carousel
                    name="recommendations-placeholder"
                    nextFocus="#budget-calculator-section"
                    ariaLabel="No Recommendations Available"
                >
                    <li className="tab-placeholder--list-item carousel__slide" key="recommendations-placeholder-tile-1">
                        <div className="carousel-slide-item">
                            <div className="tab-placeholder--supportive-copy kmx-typography--display-3">
                                Hmm… we’re having trouble loading this
                            </div>
                            <a href="/" className="tab-placeholder--cta-button kmx-button kmx-button--primary">
                                Try Reloading
                            </a>
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="recommendations-placeholder-tile-2"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="recommendations-placeholder-tile-3"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                    <li
                        className="tab-placeholder--list-item tab-placeholder--skeleton carousel__slide"
                        key="recommendations-placeholder-tile-4"
                    >
                        <div className="carousel-slide-item">
                            <SkeletonVehicleTile />
                        </div>
                    </li>
                </Carousel>
            </ul>
        </div>
    );
};
