import { Names, IHomeBase } from '../Store/models';
import { buildSavedVehicleTiles, getRecentlyViewedVehicleTiles } from '../../../../utilities/vehicleTileBuilder';
import { getStoreId } from '../../../../utilities/visitor-cookie';
import { IVehicleTile } from '../../../../types/vehicleTile';
import * as savedVehicles from '../../utilities/savedVehicles';

const dedupeVehicles = (vehiclesArray: IVehicleTile[]) => {
    const stockNumbers = vehiclesArray.map((v) => v.stockNumber);

    const filtered = vehiclesArray.filter(
        (v, index) => !stockNumbers.includes(v.stockNumber, index + 1) && v !== undefined
    );
    return filtered;
};

const processVehicles = (viewedVehicles: IVehicleTile[], savedVehicleList: IVehicleTile[]) => {
    let allVehicles: IVehicleTile[] = [];

    if (savedVehicleList.length && savedVehicleList) {
        allVehicles = allVehicles.concat(savedVehicleList);
    }

    if (viewedVehicles.length && viewedVehicles) {
        allVehicles = allVehicles.concat(viewedVehicles);
    }

    return dedupeVehicles(allVehicles);
};

export const getHomeBaseData = new Promise((resolve) => {
    getRecentlyViewedVehicleTiles(10, getStoreId()).then((viewedVehicles: IVehicleTile[]) => {
        savedVehicles.getSavedVehicles((savedStockNumbers) => {
            return buildSavedVehicleTiles(
                savedStockNumbers,
                (savedVehicleList: IVehicleTile[]) => {
                    resolve({
                        name: Names.HomeBase,
                        viewedVehicles: processVehicles(viewedVehicles, savedVehicleList),
                        savedStockNumbers,
                    });
                },
                getStoreId()
            );
        });
    });
});

export const fetchAsyncData = async () => {
    const res = await Promise.all([getHomeBaseData]);
    return res;
};

function returnExperienceByName({ experiences, name }: { experiences: any[]; name: Names }) {
    const experience = experiences.find((experience: IHomeBase | unknown) => {
        return (experience as IHomeBase).name === name;
    });
    return experience;
}

export const getComponentAndData = (experiences: (IHomeBase | unknown)[]) => {
    const homeBaseExperience = returnExperienceByName({ experiences, name: Names.HomeBase });
    // need to check if any of the experiences have truthy data
    if (homeBaseExperience?.viewedVehicles.length > 0) {
        return homeBaseExperience;
    } else {
        return { name: Names.FirstTime, experienceData: undefined };
    }
};
