import * as React from 'react';
import { useState, useEffect } from 'react';
import { HomebaseTab, HOMEBASE_CAROUSELS } from '../../../utilities/constants';
import { IVehicleTile } from '../../../../../types/vehicleTile';
import ReturnVisitorCarousel from '../../../../../components/carousels/home-base-hero-carousel/ReturnVisitorCarousel';
import { RecentlyViewedPlaceholderCarousel } from '../../../../../components/carousels/home-base-hero-carousel/RecentlyViewedPlaceholderCarousel';
import { FBSContext } from '../../../../../context';
import { useLocalCarsStore } from '../../../../../context/localCarsStore';
import { useShallow } from 'zustand/react/shallow';

interface IRecentlyViewedCarouselProps {
    isVisible: boolean;
    viewedVehicleList: IVehicleTile[];
    stockNumbersPendingFavoritesUpdate: number[];
    updatedViewedTiles?: (tileToRemove: IVehicleTile) => any;
    onOpenSnackBar: (text: string) => any;
}

const RecentlyViewedCarousel = (props: IRecentlyViewedCarouselProps) => {
    const { isVisible, viewedVehicleList, stockNumbersPendingFavoritesUpdate } = props;

    const { favoriteVehicles, setFavoriteVehicles } = useLocalCarsStore(
        useShallow((state) => ({
            favoriteVehicles: state.favoriteVehicles,
            addVehicleToFavorites: state.addVehicleToFavorites,
            removeVehicleFromFavorites: state.removeVehicleFromFavorites,
            setFavoriteVehicles: state.setFavoriteVehicles,
        }))
    );

    const fbs = React.useContext(FBSContext);
    const [viewedList, setViewedList] = useState(props.viewedVehicleList);

    useEffect(() => {
        setViewedList(props.viewedVehicleList);
        setFavoriteVehicles();
    }, [props.viewedVehicleList, setFavoriteVehicles]);

    return viewedVehicleList.length > 0 ? (
        <ReturnVisitorCarousel
            id="kmx-viewed-carousel"
            carouselName={HOMEBASE_CAROUSELS.byId[HomebaseTab.RecentlyViewed].carouselName}
            analyticsName={HOMEBASE_CAROUSELS.byId[HomebaseTab.RecentlyViewed].carouselAnalyticsName}
            isVisible={isVisible}
            vehicleTileList={viewedList}
            favoritedStockNumberList={favoriteVehicles}
            stockNumbersPendingFavoritesUpdate={stockNumbersPendingFavoritesUpdate}
            ariaLabel={HOMEBASE_CAROUSELS.byId[HomebaseTab.RecentlyViewed].ariaLabel}
            updatedViewedTiles={props.updatedViewedTiles}
            enableRemove={true}
            fbsData={fbs.fbsData}
            onOpenSnackBar={props.onOpenSnackBar}
            sectionName="Viewed"
        />
    ) : (
        <RecentlyViewedPlaceholderCarousel />
    );
};

export default RecentlyViewedCarousel;
