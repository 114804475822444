import { useEffect, useState, useCallback } from 'react';
import { CarTilesCarousel } from '../../../../../components/carousels/car-tiles/CarTilesCarousel/CarTilesCarousel';
import { useHomeStore } from '../../../HeroRefactor/Store';
import { IHomeBase } from '../../../HeroRefactor/Store/models';
import { IVehicleTile } from '../../../../../types/vehicleTile';

import { removeViewedVehicleItemLocalStorage } from '../../../../home/utilities/viewedVehiclesTileBuilder';

const OptViewed = () => {
    const { experienceData, isLoading } = useHomeStore();
    const [cars, setCars] = useState<IVehicleTile[]>([]);

    useEffect(() => {
        setCars((((experienceData as unknown) as IHomeBase).viewedVehicles || []) as IVehicleTile[]);
    }, [experienceData]);

    const onRemove = useCallback(
        (vehicle: IVehicleTile) => {
            const { stockNumber } = vehicle;

            const remaining = (cars as IVehicleTile[]).filter((v) => v.stockNumber !== stockNumber);
            const itemToRemove = (cars as IVehicleTile[]).find((v) => v.stockNumber === stockNumber);

            setCars(remaining);
            if (itemToRemove) {
                removeViewedVehicleItemLocalStorage(itemToRemove);
            }
        },
        [cars]
    );

    return (
        <>
            {!isLoading && cars.length > 0 && (
                <CarTilesCarousel
                    title="You looked at"
                    carouselId="viewed-vehicles"
                    vehiclesData={cars}
                    placeholder={{ cardText: 'Nothing feeling quite right?', buttonText: 'Browse for cars' }}
                    onRemove={onRemove}
                    isRemoveEnabled={true}
                />
            )}
            {isLoading && (
                <div style={{ textAlign: 'center', paddingTop: '300px' }}>
                    <hzn-loading tone="brand" label="loading content" size="large"></hzn-loading>
                </div>
            )}
        </>
    );
};

export default OptViewed;
