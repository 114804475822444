import axios from 'axios';
import { SearchApiResponse, Vehicle } from './types';

export function getVehicles(params: {
    stockNumberList?: number[];
    take?: number;
    scoringProfile?: string;
    transferDestinationStoreId?: string;
    zipCode?: string;
    radiusInMiles?: number;
    storeId?: string;
}): Promise<Vehicle[]> {
    try {
        let url = '/home/api/search';
        const queryParamsArray = [];

        queryParamsArray.push('isSaleable=true');

        if (params.stockNumberList && params.stockNumberList.length > 0) {
            params.stockNumberList.map((val) => {
                queryParamsArray.push(`stockNumbers=${val}`);
            });
        }

        if (params.take) {
            queryParamsArray.push(`take=${params.take}`);
        }

        if (params.scoringProfile) {
            queryParamsArray.push(`scoringProfile=${params.scoringProfile}`);
        }

        if (params.transferDestinationStoreId) {
            queryParamsArray.push(`transferDestinationStoreId=${params.transferDestinationStoreId}`);
        }

        if (params.storeId) {
            queryParamsArray.push(`storeId=${params.storeId}`);
        }

        if (params.zipCode) {
            queryParamsArray.push(`zipcode=${params.zipCode}`);
        }

        if (params.radiusInMiles) {
            queryParamsArray.push(`radiusInMiles=${params.radiusInMiles}`);
        }

        if (queryParamsArray.length) {
            url += `?${queryParamsArray.join('&')}`;
        }

        return axios
            .get<SearchApiResponse>(url)
            .then((response) => {
                if (response && response.data && response.data.items && response.data.items.length > 0) {
                    return response.data.items;
                }
                return [];
            })
            .catch(() => []);
    } catch {
        return Promise.resolve([]);
    }
}

interface getVehiclesTotalCountParams {
    zipCode?: string;
    radiusInMiles?: number;
    storeId?: string;
    isInStoreOnly?: boolean;
    hasPriceDrop?: boolean;
    hasFreeShipping?: boolean;
}

export async function getVehiclesTotalCount({
    zipCode,
    radiusInMiles,
    storeId,
    isInStoreOnly,
    hasPriceDrop,
    hasFreeShipping,
}: getVehiclesTotalCountParams): Promise<number> {
    try {
        const baseUrl = '/home/api/search';

        const params = new URLSearchParams();

        Object.entries({
            isSaleable: true,
            resultsOnly: true,
            take: 0,
            zipCode,
            radiusInMiles,
            storeId,
            isInStoreOnly,
            hasPriceDrop,
            hasFreeShipping,
        }).forEach(([field, value]) => {
            if (value !== undefined && value !== null) {
                params.append(field, value.toString());
            }
        });

        const response = await fetch(`${baseUrl}?${params}`);

        if (!response.ok) {
            return 0;
        }

        const data: SearchApiResponse = await response.json();
        return data?.totalCount;
    } catch {
        return Promise.resolve(0);
    }
}
