import { useEffect, useRef, useState } from 'react';
import { emptyContentfulLinkFarmResponse, getContentfulLinkFarm } from '../../../api/content-service-api';
import { IContentfulLinkFarmResponse, ILinkFarmTab } from '../../../api/types/content-api';
import LoadingSpinner from '../../../components/loading-spinner';
import { revealAnimation } from '../../../utilities/revealAnimation';
import LinkFarmTab from './LinkFarmTab';
import LinkFarmTabContent from './LinkFarmTabContent';

const LinkFarm = (props: any) => {
    const [activeTab, setActiveTab] = useState(0);
    const [data, setTabs] = useState<IContentfulLinkFarmResponse>(emptyContentfulLinkFarmResponse);
    const [isLoading, setIsLoading] = useState(true);
    const linkFarmRef = useRef(null);

    useEffect(() => {
        getContentfulLinkFarm().then((data) => {
            setTabs(data);
            data.tabItems.length > 0 && setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    } else {
        return (
            <div ref={linkFarmRef} className="link-farm-tabs--max-width-container">
                <h2 className="link-farm-tabs--heading" id="homepage-links">
                    Shop CarMax
                    <span className="mobile-hidden">&nbsp;</span>
                    <span className="tablet-hidden desktop-hidden">
                        <br />
                    </span>
                    your way
                </h2>
                <div id="link-farm-tabs-container" className="hp-tabs-container">
                    <nav className="hp-tabs-headers">
                        <div className="hp-tabs-headers-scroll-container" role="tablist">
                            {data.tabItems.map((tab: ILinkFarmTab, i: number) => {
                                return (
                                    <LinkFarmTab
                                        key={i}
                                        tab={tab}
                                        tabIndex={i}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                );
                            })}
                        </div>
                    </nav>
                </div>

                {data.tabItems.map((tab: ILinkFarmTab, i: number) => {
                    return (
                        <LinkFarmTabContent
                            key={i}
                            data={data}
                            activeTab={activeTab}
                            tab={tab}
                            tabIndex={i}
                            linkFarmRef={linkFarmRef}
                        />
                    );
                })}
            </div>
        );
    }
};

export default LinkFarm;
