import * as React from 'react';

class SVGs {
    static get arrowIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
                <path fill="none" d="M0 0h24v24H0V0z" />
            </svg>
        );
    }
    static get homeIcon() {
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        );
    }
    static get locationIcon() {
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" />
                <path d="M0 0h24v24H0z" fill="none" />
            </svg>
        );
    }
    static get locationMUIIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.10156 10.0805C2.10156 5.17635 6.09823 1.17969 11.0024 1.17969C15.9066 1.17969 19.9032 4.95635 19.9032 10.0805C19.9032 15.1853 14.2629 18.8526 12.3804 20.0767L12.3591 20.0905C11.9466 20.3564 11.4699 20.4939 11.0024 20.4939C10.5349 20.4939 10.0582 20.3564 9.64573 20.0905C7.77573 18.8805 2.10156 14.9847 2.10156 10.0805ZM10.3249 18.9823L10.3516 18.9997C10.7457 19.2564 11.2591 19.2564 11.6532 18.9997C13.3674 17.8905 18.6016 14.4989 18.6016 10.0805C18.6016 5.89135 15.1916 2.48135 11.0024 2.48135C6.81323 2.48135 3.40323 5.67135 3.40323 10.0805C3.40323 14.467 8.58373 17.8465 10.3249 18.9823ZM7.60156 10.0805C7.60156 8.20135 9.12323 6.67969 11.0024 6.67969C12.8816 6.67969 14.4032 8.20135 14.4032 10.0805C14.4032 11.9597 12.8816 13.4814 11.0024 13.4814C9.12323 13.4814 7.60156 11.9597 7.60156 10.0805ZM8.90323 10.0805C8.90323 11.2355 9.8474 12.1797 11.0024 12.1797C12.1574 12.1797 13.1016 11.2355 13.1016 10.0805C13.1016 8.92552 12.1574 7.98135 11.0024 7.98135C9.8474 7.98135 8.90323 8.92552 8.90323 10.0805Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
    static get signaturePath() {
        return (
            <svg
                id="buying-online-steps--signature-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="166.72"
                height="86.45"
                viewBox="0 0 166.72 86.45"
                aria-hidden="true"
                focusable="false"
            >
                <path
                    id="buying-online-steps--signature-path"
                    d="M62.73,59.39C77.26,54.76,78,26.35,78.6,10.76c0,0-.46,73.95-8.17,73.95S60.77,59,50.18,45.07C16.49.93-28.78,67.7,31,62.69c21-1.76,39-10.57,55-21.63,51.58-35.73,18.33-66.06.17-4.3C73.16,81,94.65,60.05,99,50.58c3.18-7,.33,21.91,12.83-.82,0,0-1.38,20.43,11.28,11.06,7.21-5.34,1.72-22.31-4-15.08-5.28,6.71,12.77,25.91,20.48,5.5,17.07-45.14-16.93-28.64-5.5,1.21,8.8,23,14.64,10.68,21.79,4.07,5.2-4.79-4.91,9.94,9.69,5.4"
                    transform="translate(-0.34 0.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                />
            </svg>
        );
    }
    static get calendarIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" aria-hidden="true">
                <path
                    d="M14.45,17a2.39,2.39,0,0,0,1.07-2.07c0-1.49-1.32-2.52-3.13-2.52A3.47,3.47,0,0,0,9,14.3l1.52.75a1.78,1.78,0,0,1,1.85-1.13c.93,0,1.42.54,1.42,1.07a1.18,1.18,0,0,1-1.12,1.24H11.29v1.62H12.5c.95,0,1.37.46,1.37,1.14a1.38,1.38,0,0,1-1.49,1.26,2.13,2.13,0,0,1-2-1.2l-1.52,1.1a3.91,3.91,0,0,0,3.48,1.7c2.06,0,3.26-1.23,3.26-2.81A2.18,2.18,0,0,0,14.45,17Z"
                    transform="translate(-0.93)"
                    fill="#fff"
                />
                <path
                    d="M22.84,18.84V15.46A3,3,0,0,0,19.9,12.4h-.2a3,3,0,0,0-3.13,2.86h0v3.57a2.83,2.83,0,0,0,2.65,3h0a1.85,1.85,0,0,0,.47,0,2.86,2.86,0,0,0,3.13-2.56A3.23,3.23,0,0,0,22.84,18.84Zm-4.52-.08V15.53a1.31,1.31,0,0,1,1.22-1.41h.17a1.29,1.29,0,0,1,1.38,1.19v0a.67.67,0,0,1,0,.2v3.24a1.29,1.29,0,0,1-1.24,1.33h-.16A1.26,1.26,0,0,1,18.32,19Z"
                    transform="translate(-0.93)"
                    fill="#fff"
                />
                <path
                    d="M28.5,2.07H25.72V0H24.14V2.07H7.86V0H6.28V2.07H3.5A2.58,2.58,0,0,0,.93,4.64v4H29.5V26.4c0,.84-.39,1.39-1,1.39H18l1.52-1.53-1.11-1.11L15,28.58,18.39,32l1.11-1.11L18,29.36H28.5a2.71,2.71,0,0,0,2.59-2.79V4.25A2.51,2.51,0,0,0,28.5,2.07Zm1,5H2.5V4.64a1,1,0,0,1,1-1H6.28V5.72H7.86V3.64H24.14V5.72h1.58V3.64H28.5a1.11,1.11,0,0,1,1,.61Z"
                    transform="translate(-0.93)"
                    fill="#fff"
                />
                <rect y="16.25" width="1.57" height="4.72" fill="#ffd900" />
                <rect y="9.97" width="1.57" height="4.72" fill="#ffd900" />
                <rect x="8.09" y="27.79" width="4.72" height="1.57" fill="#ffd900" />
                <path
                    d="M3.08,27.7l-.65,1.43a2.64,2.64,0,0,0,1.07.23h4V27.79h-4A1,1,0,0,1,3.08,27.7Z"
                    transform="translate(-0.93)"
                    fill="#ffd900"
                />
                <path
                    d="M2.5,26.79V22.54H.93v4.25a2.51,2.51,0,0,0,.08.67l1.52-.41A1.15,1.15,0,0,1,2.5,26.79Z"
                    transform="translate(-0.93)"
                    fill="#ffd900"
                />
            </svg>
        );
    }
}

export default SVGs;
