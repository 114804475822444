import { useEffect, useState } from 'react';
import { ISocialProofApiPost } from '../../../../api/social-proof-api';
import Icons from '../../../../utilities/icons';
import './social-proof-modal.scss';

interface ISocialProofModalProps {
    isVisible: boolean;
    card: Pick<ISocialProofApiPost, 'permaLink' | 'thumbnailUrl' | 'imgAlt' | 'authorName'>;
    onClose: () => void;
}

export const SocialProofModal = ({ isVisible, card, onClose }: ISocialProofModalProps) => {
    const [visibleCssClass, setVisibleCssClass] = useState('');

    useEffect(() => {
        let timeoutId = undefined;
        if (isVisible) {
            // To show the entrance animation, we need to wait for the component to get mounted
            timeoutId = setTimeout(() => {
                setVisibleCssClass('visible');
            }, 10);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isVisible]);

    const handleOnClose = () => {
        // To show the exit animation, we need to wait for the animation to complete before unmounting the component.
        // 250ms matches the exit animation duration in the CSS
        setVisibleCssClass('');
        setTimeout(() => {
            onClose();
        }, 250);
    };

    if (!isVisible) return <></>;

    return (
        isVisible && (
            <div className={`social-proof-modal ${visibleCssClass}`}>
                <div className="social-proof-modal-content">
                    <div className="social-proof-modal-header">
                        <button type="button" onClick={handleOnClose} className="social-proof-modal--close">
                            {Icons.close}
                        </button>
                    </div>
                    <a
                        className="social-proof-modal-background"
                        href={card.permaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={card.thumbnailUrl} alt={card.imgAlt} />
                    </a>
                    <div className="social-proof-modal-footer">
                        {Icons.instagram}
                        <a href={card.permaLink} target="_blank" rel="noopener noreferrer">
                            @{card.authorName}
                        </a>
                    </div>
                </div>
                <div className="social-proof-modal-backdrop" aria-hidden="true" onClick={handleOnClose}></div>
            </div>
        )
    );
};
