import { create } from 'zustand';
import { getCiamId } from '../api/myaccount-cookie-api';
import { IVehicleTile } from '../types/vehicleTile';
import { IFBSDecision } from '../api/types/finance';
import { getStoreZipCode, getStoreId, hasPreviouslySignedIn } from '../utilities/cookieHelper';
import { getLocalVehiclesData, getRecentlyViewedVehicleTiles } from '../utilities/vehicleTileBuilder';
import { getSmallBatchDecisions } from '../api/small-batch-api';
import { IGetCarImagesResponse } from '../types/api/getCarImagesResponse';
import { MAX_RECENTLY_VIEWED_VEHICLE_TILES } from '../pages/home/utilities/constants';
import { hideSnackBarFromExternalScript } from '../pages/home/hero/car-tile/carTileUtils';
import * as savedVehicles from '../pages/home/utilities/savedVehicles';
import { ITrackingPayload } from '../types/analytics';
import { ANALYTICS_CONSTANTS } from '../utilities/analytics';
import * as analytics from '../utilities/analytics';

export interface ILocalCarsState {
    favoriteVehicles: number[];
    fbsData: IFBSDecision[];
    ciamId: string;
    zipCode?: string;
    storeId?: string;
    radiusInMiles: number; // !TODO: Do we need this or should this be a constant in our end? "The Select your store" modal doesn't give the user an option to specify radius
    localVehicles: IVehicleTile[];
    recentlyViewedVehicles: IVehicleTile[];
    isLocalVehiclesLoading: boolean;
    isRecentlyVehiclesLoading: boolean;
}

export interface ILocalCarsActions {
    setCiam: () => Promise<void>;
    setZipCode: () => void;
    setStoreId: () => void;
    setFbsData: (stockNumbers: number[]) => Promise<void>;
    setLocalVehicles: (includeFbsData?: boolean) => Promise<void>;
    toggleFavoritesResponse: (isSuccess: boolean, componentCallback?: () => void) => void;
    addVehicleToFavorites: (stockNumber: number) => Promise<void>;
    removeVehicleFromFavorites: (stockNumber: number, hideSnackbar?: boolean) => Promise<void>;
    setRecentlyViewedVehicleTiles: (localData?: any, includeFbsData?: boolean) => Promise<void>;
    setFavoriteVehicles: () => Promise<void>;
    getCarImages: (
        stockNumber: number,
        successCallback: (response: IGetCarImagesResponse) => void,
        errorCallback: (error: unknown) => void
    ) => Promise<void>;
}

export interface ILocalCarsStore extends ILocalCarsState, ILocalCarsActions {}

export const useLocalCarsStore = create<ILocalCarsStore>((set, get) => ({
    favoriteVehicles: [],
    recentlyViewedVehicles: [],
    localVehicles: [],
    isLocalVehiclesLoading: false,
    isRecentlyVehiclesLoading: false,
    fbsData: [],
    ciamId: '',
    zipCode: undefined,
    storeId: undefined,
    radiusInMiles: 25,
    setCiam: async () => {
        const ciamId = await getCiamId();
        if (ciamId) {
            set({ ciamId });
        }
    },
    setZipCode: () => {
        const zipCode = getStoreZipCode();
        set({ zipCode });
    },
    setStoreId: () => {
        const storeId = getStoreId();
        set({ storeId });
    },
    setFbsData: async (stockNumbers: number[]) => {
        // Merge existing stock numbers from current fbsData with the new ones
        const mergedStockNumbers = [...stockNumbers, ...(get().fbsData || []).map((fbs) => fbs.stockNumber)];
        const uniqueStockNumbers = Array.from(new Set(mergedStockNumbers));

        if (uniqueStockNumbers.length > 0) {
            const fbsData = await getSmallBatchDecisions(get().ciamId, uniqueStockNumbers, getStoreId());
            set({ fbsData });
        }
    },
    setRecentlyViewedVehicleTiles: async (localData?: any, includeFbsData = true) => {
        if (localData) {
            set({ recentlyViewedVehicles: localData });
            return;
        }

        set({ isRecentlyVehiclesLoading: true });

        await get().setStoreId();
        const recentlyViewedVehicles = await getRecentlyViewedVehicleTiles(
            MAX_RECENTLY_VIEWED_VEHICLE_TILES,
            get().storeId
        );

        // Include FBS data
        if (includeFbsData && recentlyViewedVehicles?.length > 0) {
            await get().setFbsData(recentlyViewedVehicles.map(({ stockNumber }) => stockNumber));

            recentlyViewedVehicles.forEach((vehicle: IVehicleTile) => {
                const decision = get().fbsData?.find((fbs) => fbs.stockNumber === vehicle.stockNumber);

                if (decision) {
                    vehicle.fbsData = decision;
                }
            });
        }

        set({ recentlyViewedVehicles });
        set({ isRecentlyVehiclesLoading: false });
    },
    setLocalVehicles: async (includeFbsData = true) => {
        set({ isLocalVehiclesLoading: true });
        // Update zipCode
        await get().setZipCode();

        // Update storeId
        await get().setStoreId();

        // Get local vehicles
        const localVehicles = await getLocalVehiclesData({
            take: 10, // TODO: what should be the value?
            zipCode: get().zipCode || '', // TODO: what happens if we send an empty string?
            radiusInMiles: get().radiusInMiles, // TODO: what should be the value?
            storeId: get().storeId || '',
        });

        // Include FBS data
        if (includeFbsData && localVehicles?.length > 0) {
            await get().setFbsData(localVehicles.map(({ stockNumber }) => stockNumber));

            localVehicles.forEach((vehicle: IVehicleTile) => {
                const decision = get().fbsData?.find((fbs) => fbs.stockNumber === vehicle.stockNumber);

                if (decision) {
                    vehicle.fbsData = decision;
                }
            });
        }

        // Update store
        set({ localVehicles });
        set({ isLocalVehiclesLoading: false });
    },
    toggleFavoritesResponse: (isSuccess: boolean, componentCallback?: () => void) => {
        isSuccess && componentCallback && componentCallback();
    },
    addVehicleToFavorites: async (stockNumber: number) => {
        const payload: ITrackingPayload = {
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.SAVE_RECOMMENDED_CAR,
            linkDetails: {
                name: 'save car',
                position: stockNumber.toString(),
            },
        };
        analytics.trackEDDL(payload);
        const event = new CustomEvent('kmxAddSavedCar', {
            bubbles: true,
            detail: {
                stockNumber,
                callback: async (result: Promise<boolean>) => {
                    const isSuccess = await result;
                    get().toggleFavoritesResponse(isSuccess, () => {
                        set((state) => ({ favoriteVehicles: [...state.favoriteVehicles, stockNumber] }));
                    });
                },
            },
        });
        document.documentElement.dispatchEvent(event);
    },
    removeVehicleFromFavorites: async (stockNumber: number, hideSnackbar?: boolean) => {
        const payload: ITrackingPayload = {
            event: ANALYTICS_CONSTANTS.EVENTS.CLICK.CTA,
            linkDetails: {
                name: 'remove from favorites',
                position: stockNumber.toString(),
            },
        };
        analytics.trackEDDL(payload);
        const event = new CustomEvent('kmxRemoveSavedCar', {
            bubbles: true,
            detail: {
                stockNumber,
                callback: async (result: Promise<boolean>) => {
                    const isSuccess = await result;
                    get().toggleFavoritesResponse(isSuccess, () =>
                        set((state) => ({
                            favoriteVehicles: state.favoriteVehicles.filter(
                                (favoriteStockNumber) => favoriteStockNumber !== stockNumber
                            ),
                        }))
                    );
                },
            },
        });

        hideSnackbar && hideSnackBarFromExternalScript();

        document.documentElement.dispatchEvent(event);
    },
    setFavoriteVehicles: async () => {
        savedVehicles.getSavedVehicles((favoriteVehicles: number[]) => {
            set({ favoriteVehicles });
        });
    },
    getCarImages: async (
        stockNumber: number,
        successCallback: (response: IGetCarImagesResponse) => void,
        errorCallback: (error: unknown) => void
    ) => {
        try {
            const url = `https://img2.carmax.com/api/subject/${stockNumber}`;
            const response = await fetch(url, { mode: 'cors' });
            if (response.ok) {
                const data = await response.json();
                successCallback(data);
            } else if (response.status === 404) {
                throw new Error('Not Found');
            } else {
                throw new Error('Failed to fetch images');
            }
        } catch (error) {
            errorCallback(error);
        }
    },
}));

// Access localCarsStore in browser console. Might want to put behind a flag.
Object.assign(window, { _useLocalCarsStore: useLocalCarsStore });
