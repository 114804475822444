import focusOnActiveCta from './focusOnActiveCta';
import { KeyPressHandler } from './types';

const getFocusedSlideIndex = (element: HTMLElement, id: string): number => {
    let parent = element.parentNode as HTMLElement;
    while (parent && !parent.id?.includes(id)) {
        parent = parent.parentNode as HTMLElement;
    }

    // Extract index from the parent's id
    const index = parent?.id.match(/(\d+)$/)?.[1] || '0';

    return parseInt(index);
};

const getKeyPressHandler = ({ tinySlider, leaveCarousel }: KeyPressHandler): ((event: Event) => void) => (
    event: Event
): void => {
    tinySlider.pause();
    const { key, shiftKey } = event as KeyboardEvent;
    const activeEl = document.activeElement as HTMLElement | null;

    const ignoreSlideInteractiveContent = (): boolean => {
        if (
            activeEl &&
            activeEl.classList &&
            activeEl.classList.length > 0 &&
            activeEl.classList.contains('carousel-slide-interactive-content')
        ) {
            return true;
        }
        return false;
    };

    switch (key) {
        case 'Enter':
        case ' ':
        case 'Spacebar':
            if (ignoreSlideInteractiveContent()) break;
            if (activeEl) activeEl.click();
            event.preventDefault();
            break;
        case 'Escape':
        case 'Esc':
            leaveCarousel('forward');
            break;
        case 'ArrowLeft':
        case 'Left':
            tinySlider.goTo('prev');
            focusOnActiveCta(tinySlider);
            break;
        case 'ArrowRight':
        case 'Right':
            tinySlider.goTo('next');
            focusOnActiveCta(tinySlider);
            break;
        case 'ArrowUp':
        case 'Up':
            if (ignoreSlideInteractiveContent()) break;
            leaveCarousel('back');
            break;
        case 'ArrowDown':
        case 'Down':
            if (ignoreSlideInteractiveContent()) break;
            leaveCarousel('forward');
            break;
        case 'Tab':
            if (activeEl) {
                // Get the closest parent that contains the slide index in its element's id
                const indexString = getFocusedSlideIndex(activeEl, '-carousel-slides-item');

                if (indexString !== null) {
                    const currentIndex = indexString;
                    if (shiftKey) {
                        tinySlider.goTo(currentIndex - 1);
                    } else {
                        tinySlider.goTo(currentIndex);
                    }
                }
            }
            break;
        default:
    }
};

export default getKeyPressHandler;
