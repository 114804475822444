import { updateDigitalData } from './analytics';
import * as constants from './constants';
import { aprLocalStorage, IFinanceCalculatorLocalStorage } from './types';
import { IFinanceCalculator } from '../FinanceCalculator';
import { ITaxesAndFees } from '../../../../api/taxes-and-fees-api';
import * as budgetCalculatorUtils from './budgetCalculatorUtils';

const LocalStorageConstants = {
    key: 'tst-session-key',
    cacheTimeInDays: 7,
};

const cachedDataNotExpired = (cacheDate: Date, maxAgeInDays: number): boolean => {
    const expirationDate = new Date(cacheDate);
    const cacheDateDay = cacheDate.getDate();

    expirationDate.setDate(cacheDateDay + maxAgeInDays);
    const today = new Date();

    return today.getTime() < expirationDate.getTime();
};

const setFinanceInfoObjectToStore = (localStorageValue: IFinanceCalculatorLocalStorage): void => {
    localStorage.setItem(LocalStorageConstants.key, JSON.stringify(localStorageValue));
};

export const setFinanceInfoToStore = (
    apr: '0' | '1' | '2' | '3' | '4',
    term: string,
    downPayment: number,
    feeState: string,
    feesList: ITaxesAndFees[],
    monthlyPayment: number | null,
    hasInteraction = false
): void => {
    const localStorageValue: IFinanceCalculatorLocalStorage = {
        apr,
        term,
        downPayment,
        feeState,
        feesList,
        monthlyPayment,
        hasInteraction,
        updatedDateTime: new Date(),
    };

    setFinanceInfoObjectToStore(localStorageValue);
};

export const getFinanceInfoFromStore = (): IFinanceCalculatorLocalStorage | null => {
    let calculatorLocalStorage: IFinanceCalculatorLocalStorage | null = null;
    const localStorageValue = localStorage.getItem(LocalStorageConstants.key);

    if (localStorageValue) {
        calculatorLocalStorage = JSON.parse(localStorageValue);
    }

    if (
        calculatorLocalStorage &&
        calculatorLocalStorage.updatedDateTime &&
        cachedDataNotExpired(new Date(calculatorLocalStorage.updatedDateTime), LocalStorageConstants.cacheTimeInDays)
    ) {
        return calculatorLocalStorage;
    }

    return null;
};

export const persistFinanceCalculatorAndUpdateAnalytics = (
    financeCalculator: IFinanceCalculator,
    taxesAndFeesList: ITaxesAndFees[] | null[],
    hasInteraction: boolean,
    creditScores: budgetCalculatorUtils.ICreditScores | null
): void => {
    if (taxesAndFeesList.length > 0 && creditScores) {
        setFinanceInfoToStore(
            String(creditScores.allIds.indexOf(String(financeCalculator.annualInterestRate))) as aprLocalStorage,
            String(financeCalculator.loanTerm),
            financeCalculator.downPayment,
            financeCalculator.nearestStoreState,
            taxesAndFeesList as ITaxesAndFees[],
            financeCalculator.monthlyPayment,
            hasInteraction
        );
        updateDigitalData(window, financeCalculator, creditScores);
    }
};

export const persistVehicleTypePreferences = (vehicleTypeList: string[]): void => {
    localStorage.setItem(constants.vehicleTypeListLocalStorage.key, JSON.stringify(vehicleTypeList));
};
